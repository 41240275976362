import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('newsletter', () => ({
  stage_one_submit: false,
  email: '',
  email_was_valid: false,
  email_is_valid: false,
  currentStage: 0,
  fakeSubmit (e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.validateEmail(this.email)) {
      this.currentStage++;
      window.setTimeout(() => {
        this.$refs.first_name.focus();
      }, 100);
    } else {
      this.email_was_valid = true;
    }
  },
  submit () {
    this.$refs.form.submit();
  },
  onEmailInput (e) {
    this.email = e.target.value;
    this.email_is_valid = this.validateEmail(this.email);
    if (this.email_is_valid && !this.email_was_valid) {
      this.email_was_valid = true;
    }
  },
  validateEmail (email) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }
}));