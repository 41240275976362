import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.store('burgerMenuShow', false);

Alpine.data('topmenu', () => ({
  nav: null,
  progressTarget: false,
  progressStyle: { display: 'none', transform: 'scaleX(0)' },
  tTop: 0,
  tHeight: 0,
  tBottom: 0,
  timer: null,
  time: 0,
  delay: 200,
  transparentAboveTarget: null,
  aboveElement: false,
  activeArticleCarousel: null,
  carouselIndex: 0,
  href: null,
  init () {
    this.nav = this.$refs.nav ? this.$refs.nav : false;

    /*this.$watch('href', () => {
      console.log("IT CHANGED TO: " + this.href)
    })*/

    this.href = window.location.href;

    this.transparentAboveTarget = document.querySelector('*[data-header-transparent="true"]');
    if(this.transparentAboveTarget) {
      if(this.transparentAboveTarget.dataset.headerTransparentForce) {
        this.aboveElement = true;
      } else {
        this.isAboveElement();
      }
    }

    this.progressTarget = document.querySelector('*[data-reading-progress-target="true"]');

    if(this.progressTarget) {

      this.setStartValues();
      //this.observer = new ResizeObserver(this.setStartValues.bind(this)).observe(this.progressTarget);
      this.time = Date.now();
      window.addEventListener('scroll', () => {
        this.progressStyle.display = this.aboveElement ? 'none' : 'block';
        const now = Date.now();
        if (this.time + this.delay < now) {
          this.updateProgress();
          if (this.timer) clearTimeout(this.timer);
          this.timer = setTimeout(this.updateProgress.bind(this), this.delay);
          this.time = now;
          // Update above element
          if(this.transparentAboveTarget) {
            if(this.transparentAboveTarget.dataset.headerTransparentForce) {
              this.aboveElement = true;
            } else {
              this.isAboveElement();
            }
          }
        }
        this.updateProgress();
      }, {passive: true});
    }

    if(this.nav !== false) {
      this.activeArticleCarousel = this.nav.querySelector("*[data-id='article-carousel']")
    }
  },
  
  isAboveElement() {
    let rect1 = this.transparentAboveTarget.getBoundingClientRect();
    let topElt = this.$el;
    let rect2 = topElt.getBoundingClientRect();
    this.aboveElement = !(rect1.right < rect2.left || 
          rect1.left > rect2.right || 
          rect1.bottom < rect2.top || 
          rect1.top > rect2.bottom)
  },

  setStartValues () {
    if (!this.progressTarget) return;
    this.tTop = this.progressTarget.offsetTop;
    this.offsetHeight = this.progressTarget.offsetHeight;
    this.tBottom = this.tTop + this.progressTarget.offsetHeight - window.innerHeight;
    this.tHeight = this.tBottom - this.tTop;
    this.updateProgress(this.progressTarget.offsetTop + this.progressTarget.offsetHeight);
  },

  updateProgress () {
    const scroll = window.scrollY;
    if (scroll >= this.tTop && scroll <= this.tBottom + 100) {
      let percentage = (scroll - this.tTop) / this.tHeight;

      if(this.activeArticleCarousel !== null) {
        if (percentage >= 0 && percentage < 0.33) {
          this.carouselIndex = 0;
        } else if (percentage > 0.33 && percentage < 0.66) {
          this.carouselIndex = 1;
        } else {
          this.carouselIndex = 2;
        }
      }

      this.progressStyle.transform = `scaleX(${percentage})`;
    } else {
      this.progressStyle.transform = 'scaleX(1)';
    }
  },

}))
